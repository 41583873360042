import {Schedule} from "../Models/Schedule";
import {
    API_DELETE_COUNTING_SETUP_SCHEDULE,
    API_GET_COUNTING_SETUP_SCHEDULES,
    API_POST_COUNTING_SETUP_SCHEDULE
} from "../Config/Uri";
import axios from "axios";
import {GetCookie} from "../Config/Cookie";

interface GetAllCountingSetupSchedulesResult {
    data: Schedule[] | null;
    error: string | null;
}

export function startGetAllCountingSetupSchedules(id: string): Promise<GetAllCountingSetupSchedulesResult> {
    const url = API_GET_COUNTING_SETUP_SCHEDULES(id);
    return axios({ url: url, method: "GET", withCredentials: true })
        .then(res => {
            if (res.data != null) {
                const data = JSON.parse(JSON.stringify(res.data)) as Schedule[];
                return {
                    error: null,
                    data: data
                } as GetAllCountingSetupSchedulesResult;
            }
            return {
                error: null,
                data: null
            } as GetAllCountingSetupSchedulesResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as GetAllCountingSetupSchedulesResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllCountingSetupSchedulesResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllCountingSetupSchedulesResult);
            }
        });
}

export interface NewScheduleInput {
    start_date: string;
    end_date: string;
    direction_positive: boolean;
    direction_negative: boolean;
}

interface NewScheduleResult {
    data: boolean | null;
    error: string | null;
}

export function startPostNewCountingSetupSchedule(id: string, input: NewScheduleInput): Promise<NewScheduleResult> {
    const url = API_POST_COUNTING_SETUP_SCHEDULE(id);
    const cookie = GetCookie("csrf_access_token");
    return axios({ url: url, method: "POST", data: input, withCredentials: true, headers: {"X-CSRF-Token": cookie} })
        .then(res => {
            if (res.data != null) {
                return {
                    error: null,
                    data: true
                } as NewScheduleResult;
            }
            return {
                error: null,
                data: null
            } as NewScheduleResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as NewScheduleResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as NewScheduleResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as NewScheduleResult);
            }
        })
}

interface DeleteScheduleResult {
    data: boolean | null;
    error: string | null;
}

export function startDeleteSchedule(id: string, schedule_id: string): Promise<DeleteScheduleResult> {
    const url = API_DELETE_COUNTING_SETUP_SCHEDULE(id, schedule_id);
    const cookie = GetCookie("csrf_access_token");
    return axios({ url: url, method: "DELETE", withCredentials: true, headers: {"X-CSRF-Token": cookie} })
        .then(res => {
            if (res.data != null) {
                return {
                    error: null,
                    data: true
                } as DeleteScheduleResult;
            }
            return {
                error: null,
                data: null
            } as DeleteScheduleResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as DeleteScheduleResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as DeleteScheduleResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as DeleteScheduleResult);
            }
        })
}
