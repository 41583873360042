import React from "react";
import {withStreetCloudContext, WithStreetCloudContextProps} from "../Context/StreetCloudContext";
import FocusBottomTab from "./FocusBottomTab";

interface IProps extends WithStreetCloudContextProps {

}

interface IState {

}

class Bottom extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.closeTab = this.closeTab.bind(this);
    }

    closeTab(event: React.MouseEvent, id: string): void {
        event.stopPropagation();
        this.props.streetCloudContext.closeTab(id);
    }

    render() {
        return (
            <div className={"Bottom"}>
                <div className={"BottomTabWrapper"}>
                    <ul className={"BottomTabsSensor"}>
                        {
                            this.props.streetCloudContext.modalTabs.modalTabs.map(value => {
                                return(
                                    <li className={`nav-item BottomTabItem ${value.id === this.props.streetCloudContext.modalTabs.activeModalTabID ? "show" : ""}`} key={Math.random()} onClick={() => this.props.streetCloudContext.switchTab(value.id)}>
                                        <div className={"nav-link BottomTabLink"}>
                                            {value.title}
                                            <div className={"BottomTabItemClose"}
                                                 onClick={(e) => this.closeTab(e, value.id)}>
                                                <svg width={"12"} height={"11"} strokeWidth={"7"}
                                                     viewBox={"0 0 50 50"}>
                                                    <title>Close Tab</title>
                                                    <line x1={"4"} y1={"4"} x2={"46"} y2={"46"}/>
                                                    <line x1={"46"} y1={"4"} x2={"4"} y2={"46"}/>
                                                </svg>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                {
                    this.props.streetCloudContext.modalTabs.modalTabs.map(value => {
                        return(
                            <FocusBottomTab key={Math.random()} value={value} show={value.id === this.props.streetCloudContext.modalTabs.activeModalTabID}/>
                        );
                    })
                }
            </div>
        );
    }
}

export default withStreetCloudContext(Bottom);
