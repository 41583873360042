import React from "react";
import ReactDOM from "react-dom";
import "./Style/Style.sass";

export interface PromiseConfirmProps {
    title: string;
    content: string;
}

const dialogNode = document.getElementById('dialog-node');

export const customConfirm = (
    DialogContent: (props: {
        giveAnswer: (answer: boolean) => void,
        content: PromiseConfirmProps,
        cancel: string,
        oke: string
    }) => React.ReactElement,
    props: PromiseConfirmProps
): Promise<boolean> =>
    new Promise(res => {
        if (dialogNode == null) return;
        const giveAnswer = (answer: boolean) => {
            ReactDOM.unmountComponentAtNode(dialogNode)
            res(answer)
        }

        let cancel, oke: string;

        cancel = "Annuleren";
        oke = "Oke";

        ReactDOM.render(<DialogContent cancel={cancel} oke={oke} giveAnswer={giveAnswer} content={props} />, dialogNode)
    })

export const ConfirmDialog = ({ giveAnswer, content, cancel, oke }) => (
    <div className={"ConfirmDialogWrapper"}>
        <div className={"ConfirmDialog"}>
            <div className={"p-2 d-flex align-items-center justify-content-center"}>
                <div className={"ConfirmDialogContent"}>
                    <div className={"mb-2"}>
                        <h1>{content.title}</h1>
                        <p>{content.content}</p>
                    </div>
                    <div className={"ConfirmDialogActions"}>
                        <button onClick={() => giveAnswer(false)} className={"Cancel mr-2"}>{cancel}</button>
                        <button onClick={() => giveAnswer(true)} className={"Oke ml-2"}>{oke}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
