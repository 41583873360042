import axios from "axios";
import {Me} from "../Models/Me";
import {API_GET_ME} from "../Config/Uri";

export interface GetMeResult {
    error: string | null;
    data: Me | null;
}

export function startGetMe(): Promise<GetMeResult> {
    const url = API_GET_ME();
    return axios({ url: url, method: "GET", withCredentials: true })
        .then(res => {
            if (res.data != null) {
                const data = JSON.parse(JSON.stringify(res.data)) as Me;
                return {
                    error: null,
                    data: data
                } as GetMeResult;
            }
            return {
                error: null,
                data: null
            } as GetMeResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as GetMeResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetMeResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetMeResult);
            }
        })
}
