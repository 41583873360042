import axios from "axios";
import {CountingSetup, NewCountingSetup} from "../Models/CountingSetup";
import {
    API_DELETE_COUNTING_SETUP,
    API_DELETE_COUNTING_SETUP_SCHEDULE,
    API_GET_COUNTING_SETUPS,
    API_NEW_COUNTING_SETUP
} from "../Config/Uri";
import {GetCookie} from "../Config/Cookie";

export interface GetAllCountingSetupsResult {
    error: string | null;
    data: CountingSetup[] | null;
}

export function startGetAllCountingSetups(): Promise<GetAllCountingSetupsResult> {
    const url = API_GET_COUNTING_SETUPS();
    return axios({ url: url, method: "GET", withCredentials: true })
        .then(res => {
            if (res.data != null) {
                const data = JSON.parse(JSON.stringify(res.data)) as CountingSetup[];
                return {
                    error: null,
                    data: data
                } as GetAllCountingSetupsResult;
            }
            return {
                error: null,
                data: null
            } as GetAllCountingSetupsResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as GetAllCountingSetupsResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllCountingSetupsResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllCountingSetupsResult);
            }
        })
}

export interface DeleteCountingSetupResult {
    error: string | null;
    data: boolean | null;
}

export function startDeleteCountingSetup(id: string): Promise<DeleteCountingSetupResult> {
    const url = API_DELETE_COUNTING_SETUP(id);
    const cookie = GetCookie("csrf_access_token");
    return axios({ url: url, method: "DELETE", withCredentials: true, headers: {"X-CSRF-Token": cookie} })
        .then(res => {
            if (res.data != null) {
                return {
                    error: null,
                    data: true
                } as DeleteCountingSetupResult;
            }
            return {
                error: null,
                data: null
            } as DeleteCountingSetupResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as DeleteCountingSetupResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as DeleteCountingSetupResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as DeleteCountingSetupResult);
            }
        })
}

export interface NewCountingSetupInput {
    location: {
        lat: number;
        lon: number;
    };
    display_name: string;
    description: string;
    name_positive: string;
    name_negative: string;
    space: string;
}

export interface NewCountingSetupResult {
    error: string | null;
    data: boolean | null;
}

export function startPostCountingSetup(input: NewCountingSetupInput): Promise<NewCountingSetupResult> {
    const url = API_NEW_COUNTING_SETUP();
    const cookie = GetCookie("csrf_access_token");
    return axios({ url: url, method: "POST", data: input, withCredentials: true, headers: {"X-CSRF-Token": cookie} })
        .then(res => {
            if (res.data != null) {
                return {
                    error: null,
                    data: true
                } as NewCountingSetupResult;
            }
            return {
                error: null,
                data: null
            } as NewCountingSetupResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as NewCountingSetupResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as NewCountingSetupResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as NewCountingSetupResult);
            }
        })
}
