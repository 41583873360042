const BASE_URI = 'https://app.streetcloud.nl';
export const API_BASE_URI = `${BASE_URI}/api/v1`;

// ParkingBays
export const API_GET_PARKING_BAYS = () => `${API_BASE_URI}/parkingbays/`;

// CountingSetup
export const API_GET_COUNTING_SETUPS = () => `${API_BASE_URI}/countingsetups/?_start=0&_end=500&_sort=display_name`;
export const API_DELETE_COUNTING_SETUP = (id: string) => `${API_BASE_URI}/countingsetups/${id}`;
export const API_NEW_COUNTING_SETUP = () => `${API_BASE_URI}/countingsetups/`;

// Downloads
export const API_GET_ALL_DOWNLOADS = (id: string) => `${API_BASE_URI}/files/countingsetups/${id}`;
export const API_GET_DOWNLOAD_LINK = (id: string, file_name: string) => `${API_BASE_URI}/files/countingsetups/${id}/${file_name}`;

// Schedules
export const API_GET_COUNTING_SETUP_SCHEDULES = (id: string) => `${API_BASE_URI}/countingsetups/${id}/schedules`;
export const API_POST_COUNTING_SETUP_SCHEDULE = (id: string) => `${API_BASE_URI}/countingsetups/${id}/schedules`;
export const API_DELETE_COUNTING_SETUP_SCHEDULE = (id: string, schedule_id: string) => `${API_BASE_URI}/countingsetups/${id}/schedules/${schedule_id}`;

// Inquiries
export const API_GET_ALL_INQUIRIES = () => `${API_BASE_URI}/inquiries/`;
export const API_NEW_INQUIRIES = () => `${API_BASE_URI}/inquiries/`;
export const API_ADD_COUNTING_SETUP_TO_INQUIRY = (inquiry_id: string) => `${API_BASE_URI}/inquiries/${inquiry_id}/countingsetups`;

// Spaces
export const API_GET_ALL_SPACES = () => `${API_BASE_URI}/spaces/`;

// Me
export const API_GET_ME = () => `${BASE_URI}/auth/spa/identity`;


export const URI_AUTH_LOGIN = `${BASE_URI}/auth/spa/login`;
export const URI_AUTH_LOGOUT = `${BASE_URI}/auth/spa/token/remove`;
