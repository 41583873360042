import React from "react";
import "./Style/Style.sass";

interface IProps {

}

export default class IOSLoader extends React.Component<IProps, any>{
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <div className={`ispinner gray animating`}>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
                <div className={"ispinner-blade"}/>
            </div>

        );
    }
}
