import React from "react";
import DashboardLayout from "../Dashboard/DashboardLayout";
import IOSLoader from "../IOSLoader/IOSLoader";
import "./Style/Style.sass";
import {withStreetCloudContext, WithStreetCloudContextProps} from "../Context/StreetCloudContext";

interface IProps extends WithStreetCloudContextProps {

}

interface IState {

}

class Loader extends React.Component<IProps, IState> {
    render() {
        return this.props.streetCloudContext.me !== null && this.props.streetCloudContext.countingSetups !== null && this.props.streetCloudContext.totalData !== null && this.props.streetCloudContext.inquiries !== null && this.props.streetCloudContext.spaces !== null
        ?
            <DashboardLayout/>
        :
            <div id={"Loader"}>
                <IOSLoader/>
                <div className={"by_parkeagle"}>
                    <h3 className={"by_parkeagle_text"}>Parkeagle</h3>
                </div>
            </div>;
    }
}

export default withStreetCloudContext(Loader);
