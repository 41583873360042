import React from "react";
import ReactMapGL, {
    FlyToInterpolator,
    MapboxProps,
    MapLoadEvent,
    Marker,
    WebMercatorViewport,
    PointerEvent,
    NavigationControl
} from "react-map-gl";
import {Bounds} from "viewport-mercator-project";
import env from "../../production.env";
import {
    StreetCloudContextInterface,
    withStreetCloudContext,
    WithStreetCloudContextProps
} from "../Context/StreetCloudContext";
import {CountingSetup, NewCountingSetup} from "../../Models/CountingSetup";
import {lineString, bbox} from "@turf/turf";
import * as d3 from "d3";
import {NewCountingSetupDialog, newCountingSetupDialog} from "../Dialogs/NewCountingSetup/PromiseNewCountingSetup";
import SmallLogo from "../../Assets/DetailedLogo.png";
import SmallLogoWhite from "../../Assets/DetailedLogoWhite.png";
import IOSLoader from "../IOSLoader/IOSLoader";

interface IProps extends WithStreetCloudContextProps {

}

interface IState {
    viewport: MapboxProps;
    mapLoaded: boolean;
    isSatellite: boolean;
}

class Map extends React.Component<IProps, IState> {
    private MapWrapper = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            viewport: {
                latitude: 52.331219,
                longitude: 4.931943,
                zoom: 13,
                width: "100%",
                height: "100%"
            },
            mapLoaded: false,
            isSatellite: false
        }

        // Bindings
        this.onMapLoad = this.onMapLoad.bind(this);
        this.updateViewport = this.updateViewport.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.renderMarker = this.renderMarker.bind(this);
        this.onClick = this.onClick.bind(this);
        this.resetMapView = this.resetMapView.bind(this);
        this.renderTools = this.renderTools.bind(this);
        this.changeMapView = this.changeMapView.bind(this);
        this.renderInquiryMarker = this.renderInquiryMarker.bind(this);
        this.onFABClick = this.onFABClick.bind(this);
        this.getHeight = this.getHeight.bind(this);
    }

    componentDidMount() {
        const { totalData } = this.props.streetCloudContext;
        if (Array.isArray(totalData) && totalData.length > 1) {
            if (this.MapWrapper.current == null) return;

            let latitude, longitude, zoom;

            const line = lineString(totalData);
            const [minLng, minLat, maxLng, maxLat] = bbox(line);
            let positionObject = new WebMercatorViewport({...this.state.viewport, width: this.MapWrapper.current.clientWidth, height: this.MapWrapper.current.clientHeight})
                .fitBounds([[minLat, minLng], [maxLat, maxLng]], {
                    padding: 80
                });
            latitude = positionObject.latitude;
            longitude = positionObject.longitude;
            zoom = positionObject.zoom;

            const viewport = {
                ...this.state.viewport,
                width: "100%",
                height: "100%",
                longitude: longitude,
                latitude: latitude,
                zoom: zoom,
                transitionDuration: 2500,
                transitionInterpolator: new FlyToInterpolator(),
                transitionEasing: d3.easeCubic
            };
            this.setState({
                viewport: viewport
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.streetCloudContext.activeCountingSetupList !== this.props.streetCloudContext.activeCountingSetupList) {
            const activeCountingSetupList = Array.from(this.props.streetCloudContext.activeCountingSetupList);
            const { countingSetups } = this.props.streetCloudContext;
            if (countingSetups == null) return;
            if (this.MapWrapper.current == null) return;

            if (activeCountingSetupList.length === 1) {
                const queryMarker = countingSetups.find(value => value.id === activeCountingSetupList[0]);
                if (queryMarker == null || queryMarker.location == null) return;
                const { longitude, latitude, zoom } = new WebMercatorViewport({...this.state.viewport, width: this.MapWrapper.current.clientWidth, height: this.MapWrapper.current.clientHeight})
                    .fitBounds([[queryMarker.location.coordinates[0][1], queryMarker.location.coordinates[0][0]], [queryMarker.location.coordinates[0][1], queryMarker.location.coordinates[0][0]]]);

                const viewport = {
                    ...this.state.viewport,
                    longitude,
                    latitude,
                    zoom: zoom - 8,
                    transitionDuration: 2000,
                    transitionInterpolator: new FlyToInterpolator(),
                    transitionEasing: d3.easeCubic
                };

                this.setState({
                    viewport: viewport
                });
            } else if (activeCountingSetupList.length > 1) {
                const coordinates: Array<Array<number>> = [];

                activeCountingSetupList.forEach(value => {
                    const queryMarker = countingSetups.find(value1 => value1.id === value);
                    if (queryMarker != null && queryMarker.location != null) {
                        coordinates.push([queryMarker.location.coordinates[0][1], queryMarker.location.coordinates[0][0]]);
                    }
                });

                if (coordinates.length === 1) {
                    coordinates.push(coordinates[0]);
                }

                const { longitude, latitude, zoom } = new WebMercatorViewport({...this.state.viewport, width: this.MapWrapper.current.clientWidth, height: this.MapWrapper.current.clientHeight})
                    .fitBounds(coordinates as Bounds);

                const viewport = {
                    ...this.state.viewport,
                    longitude,
                    latitude,
                    zoom: zoom - 8,
                    transitionDuration: 2000,
                    transitionInterpolator: new FlyToInterpolator(),
                    transitionEasing: d3.easeCubic
                };

                this.setState({
                    viewport: viewport
                });
            }
        }
    }

    updateViewport(viewport: MapboxProps): void {
        this.setState({
            viewport: {
                ...viewport,
                width: "100%",
                height: "100%"
            }
        });
    }

    onMapLoad(event: MapLoadEvent): void {
        if (event.target.loaded()) {
            this.setState({
                mapLoaded: true
            })
        }
    }

    resetMapView(): void {
        const { totalData } = this.props.streetCloudContext;
        if (Array.isArray(totalData)) {
            if (this.MapWrapper.current == null) return;

            let latitude, longitude, zoom;

            const line = lineString(totalData);
            const [minLng, minLat, maxLng, maxLat] = bbox(line);
            let positionObject = new WebMercatorViewport({...this.state.viewport, width: this.MapWrapper.current.clientWidth, height: this.MapWrapper.current.clientHeight})
                .fitBounds([[minLat, minLng], [maxLat, maxLng]], {
                    padding: 80
                });
            latitude = positionObject.latitude;
            longitude = positionObject.longitude;
            zoom = positionObject.zoom;

            const viewport = {
                ...this.state.viewport,
                width: "100%",
                height: "100%",
                longitude: longitude,
                latitude: latitude,
                zoom: zoom,
                transitionDuration: 2500,
                transitionInterpolator: new FlyToInterpolator(),
                transitionEasing: d3.easeCubic
            };
            this.setState({
                viewport: viewport
            });
        }
    }

    changeMapView(): void {
        this.setState({
            isSatellite: !this.state.isSatellite
        });
    }

    renderTools(): JSX.Element {
        return (
            <div className={"NavigationControl"}>
                <NavigationControl/>
                <div className={"mapboxgl-ctrl-group mt-2"}>
                    <div className={"mapboxgl-ctrl"}>
                        <button onClick={this.resetMapView} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className={`mapboxgl-ctrl-icon mapboxgl-ctrl-satellite-view`} type={"button"} title={"Recenter view"}>
                            <i className={"fas fa-search"}/>
                        </button>
                    </div>
                </div>
                <div className={"mapboxgl-ctrl-group mt-2"}>
                    <div className={"mapboxgl-ctrl"}>
                        <button onClick={this.changeMapView} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className={`mapboxgl-ctrl-icon mapboxgl-ctrl-satellite-view`} type={"button"} title={this.state.isSatellite ? "Default View" : "Satellite View"}>
                            <i className={"fas fa-layer-group"}/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    onClickHandler(id: string): void {
        this.props.streetCloudContext.sidebarOnClick(id);
    }

    renderMarker(countingSetup: CountingSetup): JSX.Element|null {
        const { location, display_name, status, id } = countingSetup;
        if (location == null || location.coordinates == null) return null;
        return (
            <Marker captureClick={true} key={`marker-${id}`} longitude={location.coordinates[0][1]} latitude={location.coordinates[0][0]}>
                <div onClick={() => this.onClickHandler(id)} className={`countingSetupMarker ${status} ${this.props.streetCloudContext.activeCountingSetupList.has(id) ? "activeCountingSetup" : ""}`}>
                    <span>{display_name}</span>
                </div>
            </Marker>
        );
    }

    renderInquiryMarker(countingSetup: NewCountingSetup): JSX.Element|null {
        const { location, name } = countingSetup;
        if (location.lat == null || location.lon == null) return null;
        return (
            <Marker captureClick={true} key={Math.random()} longitude={location.lon} latitude={location.lat}>
                <div className={`countingSetupMarker Inquiry`}>
                    <span>{name}</span>
                </div>
            </Marker>
        );
    }

    async onClick(e: PointerEvent): Promise<void> {
        if (this.props.streetCloudContext.show_new_inquiry) {
            // TODO: Use this on deployment
            await newCountingSetupDialog(NewCountingSetupDialog, {location: {lat: e.lngLat[1], lon: e.lngLat[0]}, name: e.features.length > 0 ? e.features[0].properties.name || "" : "", space: this.props.streetCloudContext.spaces !== null ? this.props.streetCloudContext.spaces[0].id : ""})
                .then(res => {
                    if (!res.cancel && res.data != null) {
                        this.props.streetCloudContext.onSubmitNewCountingSetup(res.data);
                    }
                });
            // await newCountingSetupDialog(NewCountingSetupDialog, {location: {lat: e.lngLat[1], lon: e.lngLat[0]}, name: e.features.length > 0 ? e.features[0].properties.name || "" : "", space: "24de407a"})
            //     .then(res => {
            //         if (!res.cancel && res.data != null) {
            //             this.props.streetCloudContext.onSubmitNewCountingSetup(res.data);
            //         }
            //     });
        } else {
            if (this.props.streetCloudContext.activeCountingSetupList.size > 0) {
                this.props.streetCloudContext.onEmptySpotClick();
            }
        }
    }

    onFABClick(): void {
        this.props.streetCloudContext.changeShowNewInquiry(!this.props.streetCloudContext.show_new_inquiry);
    }

    getHeight(props: StreetCloudContextInterface): string {
        if (props.show_new_inquiry) {
            if (props.modalTabs.modalTabs.length > 0) {
                return "90%";
            } else {
                return "100%";
            }
        } else {
            if (props.modalTabs.activeModalTabID !== null) {
                return "50%";
            } else {
                if (props.modalTabs.modalTabs.length > 0) {
                    return "90%";
                } else {
                    return "100%";
                }
            }
        }
        return "100%";
    }

    render() {
        return (
            <>
                <div id={"loadingMap"} className={this.state.mapLoaded ? 'loaded' : 'not_loaded'}>
                    <div id={"loadingMapActivityIndicator"}>
                        <IOSLoader/>
                    </div>
                </div>
                <div className={"Map"} style={{ height: `${this.props.streetCloudContext.top.height}` }} ref={this.MapWrapper}>
                    <ReactMapGL mapboxApiAccessToken={env.mapBoxToken} getCursor={this.props.streetCloudContext.show_new_inquiry ? () => "pointer" : undefined} onClick={this.onClick} onLoad={this.onMapLoad} mapStyle={this.state.isSatellite ? "mapbox://styles/mapbox/satellite-v9" : "mapbox://styles/parkeagle/ck42k79xi02kf1cmyljveiqcc"} transitionDuration={1500} {...this.state.viewport} onViewportChange={this.updateViewport}>
                        {this.renderTools()}
                        {this.props.streetCloudContext.show_new_inquiry && this.props.streetCloudContext.add_inquiry_form.counting_setups.map(this.renderInquiryMarker)}
                        {this.props.streetCloudContext.countingSetups !== null && this.props.streetCloudContext.countingSetups.filter(value => value.status != null && value.status !== "unknown" && value.location != null).map(this.renderMarker)}
                        <img src={this.state.isSatellite ? SmallLogoWhite : SmallLogo} className={"Small-Logo-Map"} alt={"Parkeagle Small Logo"}/>
                    </ReactMapGL>
                    <div className={"InquiryButton"} onClick={this.onFABClick}>
                        {this.props.streetCloudContext.show_new_inquiry ? <i className={"fas fa-times"}/> : <i className={"fas fa-plus"}/>}
                    </div>
                </div>
            </>
        );
    }
}

export default withStreetCloudContext(Map);
