import axios from "axios";
import {URI_AUTH_LOGOUT} from "../Config/Uri";

export function startLogout(): Promise<boolean> {
    return axios({ url: URI_AUTH_LOGOUT, method: "POST" })
        .then(res => {
            return true;
        })
        .catch(err => {
            return true
        });
}
