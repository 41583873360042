import React from 'react';
import ReactDOM from 'react-dom';
import "./App.sass";
import * as serviceWorker from './serviceWorker';
import Loader from "./Components/Loader/Loader";
import StreetCloudContextWrapper from "./Components/Context/StreetCloudContext";
import {createIntl, createIntlCache, RawIntlProvider, IntlShape} from "react-intl";
import DutchLocale from "./Intl/nl.json";
import axios from "axios";
import {URI_AUTH_LOGIN} from "./Config/Uri";
import weekOfYear from "dayjs/plugin/weekOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "dayjs/locale/nl";

dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);

export let intlStore: IntlShape;

// let messagesLang;
// const messages = {
//     'nl': DutchLocale,
//     'en': EnglishLocale
// };
// const language = navigator.language.split(/[-_]/)[0];
// if (language in messages) {
//     messagesLang = language;
// } else {
//     messagesLang = 'en';
// }
// const cache = createIntlCache();
// intlStore = createIntl({
//     locale: language,
//     defaultLocale: 'en',
//     messages: messages[messagesLang]
// }, cache);

dayjs.locale('nl');

const cache = createIntlCache();

intlStore = createIntl({
    locale: 'nl',
    defaultLocale: 'nl',
    messages: DutchLocale
}, cache);

axios.interceptors.response.use(
    response => response,
    (error) => {
        if (typeof error.response === 'undefined') {
            window.location.replace(URI_AUTH_LOGIN);
            // return Promise.reject(error);
        } else {
            const status = error.response ? error.response.status : null;
            if (status === 401) {
                window.location.replace(URI_AUTH_LOGIN);
            } else if (status >= 500) {
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        }
    }
);

ReactDOM.render(
  <React.StrictMode>
    <StreetCloudContextWrapper>
        <RawIntlProvider value={intlStore}>
            <Loader/>
        </RawIntlProvider>
    </StreetCloudContextWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
