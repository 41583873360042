import axios from "axios";
import {API_ADD_COUNTING_SETUP_TO_INQUIRY, API_GET_ALL_INQUIRIES, API_NEW_INQUIRIES} from "../Config/Uri";
import {Inquiry} from "../Models/Inquiry";
import {GetCookie} from "../Config/Cookie";

interface GetAllInquiriesResult {
    data: Inquiry[] | null;
    error: string | null;
}

export function startGetAllInquiries(): Promise<GetAllInquiriesResult> {
    const url = API_GET_ALL_INQUIRIES();
    return axios({ url: url, method: "GET", withCredentials: true })
        .then(res => {
            if (res.data != null) {
                const data = JSON.parse(JSON.stringify(res.data)) as Inquiry[];
                return {
                    error: null,
                    data: data
                } as GetAllInquiriesResult;
            }
            return {
                error: null,
                data: null
            } as GetAllInquiriesResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as GetAllInquiriesResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllInquiriesResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllInquiriesResult);
            }
        })
}

export interface NewInquiryInput {
    name: string;
    description: string;
    counting_setups: NewInquiryCountingSetup[];
}

export interface NewInquiryCountingSetup {
    name: string;
    description: string;
    name_positive: string;
    name_negative: string;
    location: {
        lat: number;
        lon: number;
    };
    space: string;
}

export interface NewInquiryResult {
    data: boolean | null;
    error: string | null;
}

export function startCreateNewInquiry(input: NewInquiryInput): Promise<NewInquiryResult> {
    const url = API_NEW_INQUIRIES();
    const cookie = GetCookie("csrf_access_token");
    return axios({ url: url, method: "POST", data: input, withCredentials: true, headers: {"X-CSRF-Token": cookie} })
        .then(res => {
            if (res.data != null) {
                return {
                    error: null,
                    data: true
                } as NewInquiryResult;
            }
            return {
                error: null,
                data: null
            } as NewInquiryResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as NewInquiryResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as NewInquiryResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as NewInquiryResult);
            }
        })
}

export interface AddNewCountingSetupToInquiryInput {
    inquiry_id: string;
    counting_setups: AddNewCountingSetupToInquiry[];
}

export interface AddNewCountingSetupToInquiry {
    name: string;
    description: string;
    name_positive: string;
    name_negative: string;
    location: {
        lat: number;
        lon: number;
    };
    space: string;
}

export interface AddNewCountingSetupToInquiryResult {
    data: boolean | null;
    error: string | null;
}

export function startAddCountingSetupsToInquiry(input: AddNewCountingSetupToInquiryInput): Promise<AddNewCountingSetupToInquiryResult> {
    const url = API_ADD_COUNTING_SETUP_TO_INQUIRY(input.inquiry_id);
    const cookie = GetCookie("csrf_access_token");
    return axios({ url: url, method: "POST", data: input.counting_setups, withCredentials: true, headers: {"X-CSRF-Token": cookie} })
        .then(res => {
            if (res.data != null) {
                return {
                    error: null,
                    data: true
                } as AddNewCountingSetupToInquiryResult;
            }
            return {
                error: null,
                data: null
            } as AddNewCountingSetupToInquiryResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as AddNewCountingSetupToInquiryResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as AddNewCountingSetupToInquiryResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as AddNewCountingSetupToInquiryResult);
            }
        })
}
