import React, {createRef} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {ISO8601_week_no} from "../../Helpers/WeekNumber";
import {startPostNewCountingSetupSchedule} from "../../Actions/Schedule";
import dayjs from "dayjs";
import {withStreetCloudContext, WithStreetCloudContextProps} from "../Context/StreetCloudContext";
import {FormattedMessage} from "react-intl";

const modalRoot = document.getElementById('dialog-node');

class WeekPickerModal extends React.Component {
    private el: HTMLDivElement;

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        if (modalRoot !== null) {
            modalRoot.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (modalRoot !== null) {
            modalRoot.removeChild(this.el);
        }
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}

interface Week {
    week_number: number;
    start_date: Date;
    end_date: Date;
}

interface IProps extends WithStreetCloudContextProps {
    title: string;
    dataId: string;
}

interface IState {
    focused: boolean;
    weeks: Week[];
    selected_start_index: number | null;
    selected_end_index: number | null;
    show_start_menu: boolean;
    show_end_menu: boolean;
}

class WeekPicker extends React.Component<IProps, IState> {
    private startMenuRef = createRef<HTMLDivElement>();
    private endMenuRef = createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        // State
        this.state = {
            focused: false,
            weeks: [],
            selected_start_index: null,
            selected_end_index: null,
            show_start_menu: false,
            show_end_menu: false
        }

        // Bindings
        this.onScheduleSubmit = this.onScheduleSubmit.bind(this);
        this.onScheduleCancel = this.onScheduleCancel.bind(this);
        this.toggleFocus = this.toggleFocus.bind(this);
        this.onStartChange = this.onStartChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
        this.toggleStartMenu = this.toggleStartMenu.bind(this);
        this.toggleEndMenu = this.toggleEndMenu.bind(this);
        this.onBottomClick = this.onBottomClick.bind(this);
        this.onTopClick = this.onTopClick.bind(this);

        this.startMenuRef = React.createRef();
        this.endMenuRef = React.createRef();
    }

    static propTypes = {
        title: PropTypes.string.isRequired,
        startReloadOpenTabSchedules: PropTypes.func,
        dataId: PropTypes.string.isRequired
    }

    onScheduleSubmit(e) {
        e.preventDefault();

        const {selected_start_index, selected_end_index, weeks} = this.state;

        if (selected_start_index !== null && selected_end_index !== null) {
            startPostNewCountingSetupSchedule(this.props.dataId, {
                start_date: new Date(weeks[selected_start_index].start_date).toISOString(),
                end_date: new Date(weeks[selected_end_index].end_date).toISOString(),
                "direction_positive": true,
                "direction_negative": true
            }).then(() => {
                this.props.streetCloudContext.startReloadOpenTabSchedules(this.props.dataId);
                this.setState({
                    selected_start_index: null,
                    selected_end_index: null
                });
                this.toggleFocus(false);
            })
        }
    }

    onScheduleCancel(e) {
        e.preventDefault();

        this.setState({
            selected_start_index: null,
            selected_end_index: null
        });
        this.toggleFocus(false);
    }

    componentDidMount() {
        const weeks: Week[] = [];

        let startDate = this.getStartDateFromWeek();

        for (let x = 0; x < 200; x++) {
            const subStartDate = new Date(startDate);
            const subEndDate = this.getEndDateFromWeek(startDate);

            weeks.push({
                week_number: ISO8601_week_no(subStartDate),
                start_date: subStartDate,
                end_date: subEndDate
            });

            startDate.setDate(startDate.getDate() + 7);
        }

        this.setState({
            weeks: weeks
        });
    }

    toggleFocus(focus) {
        this.setState({
            focused: focus
        });
    }

    getStartDateFromWeek() {
        const dt = new Date();
        const currentWeekDay = dt.getDay();
        const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
        return new Date(new Date(dt).setDate(dt.getDate() - lessDays));
    }

    getEndDateFromWeek(dt) {
        const currentWeekDay = dt.getDay();
        const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
        const wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
        return new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
    }

    onStartChange(index) {
        if (this.state.selected_end_index !== null && this.state.selected_end_index >= index) {
            this.setState({
                selected_start_index: index,
                show_start_menu: false
            });
        } else {
            this.setState({
                selected_start_index: index,
                selected_end_index: index,
                show_start_menu: false
            });
        }
    }

    onEndChange(index) {
        this.setState({
            selected_end_index: index,
            show_end_menu: false
        });
    }

    toggleStartMenu(e) {
        e.preventDefault();
        this.setState({
            show_start_menu: !this.state.show_start_menu
        });
    }

    toggleEndMenu(e) {
        e.preventDefault();
        this.setState({
            show_end_menu: !this.state.show_end_menu
        });
    }

    onBottomClick(e) {
        e.preventDefault();
        if (this.state.show_start_menu) {
            if (this.startMenuRef.current != null) {
                this.startMenuRef.current.scrollTop += 200;
            }
        } else if (this.state.show_end_menu) {
            if (this.endMenuRef.current != null) {
                this.endMenuRef.current.scrollTop += 200;
            }
        }
    }

    onTopClick(e) {
        e.preventDefault();
        if (this.state.show_start_menu) {
            if (this.startMenuRef.current != null) {
                this.startMenuRef.current.scrollTop -= 200;
            }
        } else if (this.state.show_end_menu) {
            if (this.endMenuRef.current != null) {
                this.endMenuRef.current.scrollTop -= 200;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.focused
                    &&
                    <WeekPickerModal>
                        <div className={"WeekPickerModal"}>
                            <div className={"WeekPickerModalContent"}>
                                <div className={"WeekPickerModalContentWrapper"}>
                                    <div className={"WeekPickerModalContentTitle mb-3"}>
                                        <h2 className={"WeekPickerTitle"}>
                                            {this.props.title}
                                        </h2>
                                    </div>
                                    <div className={"row w-100 mt-3"}>
                                        <div className={"col pr-2"}>
                                            <div className={"form-group w-100"}>
                                                <p className={"weekPickerLabel"}>
                                                    <FormattedMessage id={"weekpicker.labels.start"} defaultMessage={"Start"}/>
                                                </p>
                                                <div className={"WeekPickerSelectInput"} onClick={this.toggleStartMenu}>
                                                    {
                                                        this.state.selected_start_index !== null
                                                            ?
                                                            <p><FormattedMessage id={"weekpicker.text.week"} defaultMessage={"Week"}/>: {this.state.weeks[this.state.selected_start_index].week_number} {dayjs(this.state.weeks[this.state.selected_start_index].start_date).format('DD MMM, YYYY')}</p>
                                                            :
                                                            <p className={"placeholder"}><FormattedMessage id={"weekpicker.text.start.select_start_week"} defaultMessage={"Selecteer een start week"}/></p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col pl-2"}>
                                            {
                                                this.state.selected_start_index !== null
                                                &&
                                                <div className={"form-group w-100"}>
                                                    <p className={"weekPickerLabel"}>
                                                        <FormattedMessage id={"weekpicker.labels.end"} defaultMessage={"Eind"}/>
                                                    </p>
                                                    <div className={"WeekPickerSelectInput"} onClick={this.toggleEndMenu}>
                                                        {
                                                            this.state.selected_end_index !== null
                                                                ?
                                                                <p><FormattedMessage id={"weekpicker.text.week"} defaultMessage={"Week"}/>: {this.state.weeks[this.state.selected_end_index].week_number} - {dayjs(this.state.weeks[this.state.selected_end_index].end_date).format('DD MMM, YYYY')}</p>
                                                                :
                                                                <p className={"placeholder"}><FormattedMessage id={"weekpicker.text.start.select_end_week"} defaultMessage={"Selecteer een eind week"}/></p>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={"row w-100 mt-3"}>

                                    </div>
                                    <div className={"row w-100 mt-3"}>
                                        <div className={"col pr-2"}>
                                            <button
                                                className={"btn d-flex align-items-center justify-content-center streetcloud_button red"}
                                                onClick={this.onScheduleCancel}>
                                                <FormattedMessage id={"weekpicker.buttons.cancel"} defaultMessage={"Annuleren"}/>
                                            </button>
                                        </div>
                                        <div className={"col pl-2"}>
                                            {
                                                this.state.selected_start_index === null
                                                    ?
                                                    this.state.selected_end_index === null
                                                        ?
                                                        <button
                                                            className={"btn d-flex align-items-center justify-content-center streetcloud_button"}
                                                            onClick={this.onScheduleSubmit} disabled={true}>
                                                            <FormattedMessage id={"weekpicker.buttons.submit"} defaultMessage={"Indienen"}/>
                                                        </button>
                                                        :
                                                        <button
                                                            className={"btn d-flex align-items-center justify-content-center streetcloud_button"}
                                                            onClick={this.onScheduleSubmit} disabled={true}>
                                                            <FormattedMessage id={"weekpicker.buttons.submit"} defaultMessage={"Indienen"}/>
                                                        </button>
                                                    :
                                                    this.state.selected_end_index === null
                                                        ?
                                                        <button
                                                            className={"btn d-flex align-items-center justify-content-center streetcloud_button"}
                                                            onClick={this.onScheduleSubmit} disabled={true}>
                                                            <FormattedMessage id={"weekpicker.buttons.submit"} defaultMessage={"Indienen"}/>
                                                        </button>
                                                        :
                                                        <button
                                                            className={"btn d-flex align-items-center justify-content-center streetcloud_button"}
                                                            onClick={this.onScheduleSubmit} disabled={false}>
                                                            <FormattedMessage id={"weekpicker.buttons.submit"} defaultMessage={"Indienen"}/>
                                                        </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WeekPickerModal>
                }
                <div className={"WeekPickerInput form-group"}>
                    <button
                        className={"btn streetcloud_button btn-submit mb-2 d-flex justify-content-center align-items-center"}
                        type={"submit"} onClick={() => this.toggleFocus(true)}>
                        <FormattedMessage id={"bottom_tabs.focus.schedule_counting_setup.button.text"} defaultMessage={"Plan nieuwe telling in"}/>
                    </button>
                </div>
                {
                    this.state.show_start_menu
                    &&
                    <div className={"WeekPickerSelectMenu"}>
                        <div className={"WeekPickerSelectMenuContainer"}>
                            {/*<div className={"WeekPickerSelectMenuClose"} onClick={this.toggleStartMenu}>*/}
                            {/*    <i className={"fas fa-times"}/>*/}
                            {/*</div>*/}
                            <div className={"WeekPickerMenuWrapper"}>
                                <div className={"WeekPickerMenuTitle d-flex justify-content-center align-items-center"}>
                                    <h3>
                                        <FormattedMessage id={"weekpicker.text.start.select_start_week"} defaultMessage={"Selecteer een start week"}/>
                                    </h3>
                                </div>
                                <div className={"WeekPickerMenuScroll d-flex justify-content-center align-items-center"} onClick={this.onTopClick}>
                                    <i className={"fas fa-chevron-up cursor-pointer mt-1 mb-1"}/>
                                </div>
                                <div className={"WeekPickerMenuMenu"} ref={this.startMenuRef}>
                                    {
                                        this.state.weeks.map((value, index) => {
                                            if (ISO8601_week_no(value.start_date) === 1) {
                                                return(
                                                    <React.Fragment key={Math.random()}>
                                                        <div className={"WeekPickerMenuItemYear"}>
                                                            {dayjs(value.start_date).format('YYYY')}
                                                        </div>
                                                        <div onClick={() => this.onStartChange(index)} className={`WeekPickerSelectMenuItem ${this.state.selected_start_index === index ? "active" : ""}`}>
                                                            <FormattedMessage id={"weekpicker.text.week"} defaultMessage={"Week"}/>: {value.week_number} {dayjs(value.start_date).format('DD MMM')} - {dayjs(value.end_date).format('DD MMM, YYYY')}
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            }
                                            return(
                                                <div onClick={() => this.onStartChange(index)} className={`WeekPickerSelectMenuItem ${this.state.selected_start_index === index ? "active" : ""}`} key={Math.random()}>
                                                    <FormattedMessage id={"weekpicker.text.week"} defaultMessage={"Week"}/>: {value.week_number} {dayjs(value.start_date).format('DD MMM')} - {dayjs(value.end_date).format('DD MMM, YYYY')}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className={"WeekPickerMenuScroll d-flex justify-content-center align-items-center"} onClick={this.onBottomClick}>
                                    <i className={"fas fa-chevron-down cursor-pointer mt-1 mb-1"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.show_end_menu
                    &&
                    <div className={"WeekPickerSelectMenu"}>
                        <div className={"WeekPickerSelectMenuContainer"}>
                            {/*<div className={"WeekPickerSelectMenuClose"} onClick={this.toggleStartMenu}>*/}
                            {/*    <i className={"fas fa-times"}/>*/}
                            {/*</div>*/}
                            <div className={"WeekPickerMenuWrapper"}>
                                <div className={"WeekPickerMenuTitle d-flex justify-content-center align-items-center"}>
                                    <h3>
                                        <FormattedMessage id={"weekpicker.text.start.select_end_week"} defaultMessage={"Selecteer een eind week"}/>
                                    </h3>
                                </div>
                                <div className={"WeekPickerMenuScroll d-flex justify-content-center align-items-center"} onClick={this.onTopClick}>
                                    <i className={"fas fa-chevron-up cursor-pointer mt-1 mb-1"}/>
                                </div>
                                <div className={"WeekPickerMenuMenu"} ref={this.endMenuRef}>
                                    {
                                        this.state.weeks.map((value, index) => {
                                            if (this.state.selected_start_index !== null && this.state.selected_start_index > index) {
                                                return null;
                                            } else {
                                                if (ISO8601_week_no(value.start_date) === 1) {
                                                    return(
                                                        <React.Fragment key={Math.random()}>
                                                            <div className={"WeekPickerMenuItemYear"}>
                                                                {dayjs(value.start_date).format('YYYY')}
                                                            </div>
                                                            <div onClick={() => this.onEndChange(index)} className={`WeekPickerSelectMenuItem ${this.state.selected_end_index === index ? "active" : ""}`}>
                                                                <FormattedMessage id={"weekpicker.text.week"} defaultMessage={"Week"}/>: {value.week_number} {dayjs(value.start_date).format('DD MMM')} - {dayjs(value.end_date).format('DD MMM, YYYY')}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }
                                                return(
                                                    <div onClick={() => this.onEndChange(index)} className={`WeekPickerSelectMenuItem ${this.state.selected_end_index === index ? "active" : ""}`} key={Math.random()}>
                                                        <FormattedMessage id={"weekpicker.text.week"} defaultMessage={"Week"}/>: {value.week_number} {dayjs(value.start_date).format('DD MMM')} - {dayjs(value.end_date).format('DD MMM, YYYY')}
                                                    </div>
                                                );
                                            }
                                        })
                                    }
                                </div>
                                <div className={"WeekPickerMenuScroll d-flex justify-content-center align-items-center"} onClick={this.onBottomClick}>
                                    <i className={"fas fa-chevron-down cursor-pointer mt-1 mb-1"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default withStreetCloudContext(WeekPicker);
