import {API_GET_ALL_DOWNLOADS, API_GET_DOWNLOAD_LINK} from "../Config/Uri";
import axios from "axios";
import {Download, DownloadLink} from "../Models/Tabs";

interface GetAllDownloadsResult {
    data: Download[] | null;
    error: string | null;
}

export function startGetAllCountingSetupDownloads(id: string): Promise<GetAllDownloadsResult> {
    const url = API_GET_ALL_DOWNLOADS(id);
    return axios({ url: url, method: "GET", withCredentials: true })
        .then(res => {
            if (res.data != null) {
                const data = JSON.parse(JSON.stringify(res.data)) as Download[];
                return {
                    error: null,
                    data: data
                } as GetAllDownloadsResult;
            }
            return {
                error: null,
                data: null
            } as GetAllDownloadsResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as GetAllDownloadsResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllDownloadsResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetAllDownloadsResult);
            }
        });
}

interface GetDownloadLinkResult {
    data: DownloadLink | null;
    error: string | null;
}


export function startGetCountingSetupDownloadLink(id: string, filename: string): Promise<GetDownloadLinkResult> {
    const url = API_GET_DOWNLOAD_LINK(id, filename);
    return axios({ url: url, method: "GET", withCredentials: true })
        .then(res => {
            if (res.data != null) {
                const data = JSON.parse(JSON.stringify(res.data)) as DownloadLink;
                return {
                    error: null,
                    data: data
                } as GetDownloadLinkResult;
            }
            return {
                error: null,
                data: null
            } as GetDownloadLinkResult;
        })
        .catch(err => {
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject({
                    error: err.response.data.message,
                    data: null
                } as GetDownloadLinkResult);
            } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetDownloadLinkResult);
            } else {
                // anything else
                return Promise.reject({
                    error: err.message,
                    data: null
                } as GetDownloadLinkResult);
            }
        });
}
