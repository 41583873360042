import React from "react";
import Sidebar from "./Sidebar";
import Map from "./Map";
import Bottom from "./Bottom";
import "./Style/Style.sass";
import {withStreetCloudContext, WithStreetCloudContextProps} from "../Context/StreetCloudContext";

interface IProps extends WithStreetCloudContextProps {

}

interface IState {

}

class DashboardLayout extends React.Component<IProps, IState> {
    render() {
        return (
            <div id={"DashboardLayout"}>
                <div className={"Left"}>
                    <Map/>
                    <Bottom/>
                </div>
                <div className={"Right"}>
                    <Sidebar/>
                </div>
            </div>
        );
    }
}

export default withStreetCloudContext(DashboardLayout);
