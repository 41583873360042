import React from "react";
import {withStreetCloudContext, WithStreetCloudContextProps} from "../Context/StreetCloudContext";
import {CountingSetup, NewCountingSetup} from "../../Models/CountingSetup";
import { FormattedMessage } from "react-intl";
import Logo from "../../Assets/Logo.png";
import {URI_AUTH_LOGIN, URI_AUTH_LOGOUT} from "../../Config/Uri";
import {startLogout} from "../../Actions/Auth";

interface IProps extends WithStreetCloudContextProps {

}

interface IState {

}

class Sidebar extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.renderCountingSetup = this.renderCountingSetup.bind(this);
        this.renderNewCountingSetup = this.renderNewCountingSetup.bind(this);
        this.onClickLogout = this.onClickLogout.bind(this);
    }

    onClick(id: string): void {
        this.props.streetCloudContext.sidebarOnClick(id);
    }

    renderCountingSetup(countingSetup: CountingSetup): JSX.Element|null {
        const { activeCountingSetupList } = this.props.streetCloudContext;
        const { display_name, id, status } = countingSetup;
        if (display_name == null) return null;
        return (
            <li key={Math.random()} className={`list-group-item ${activeCountingSetupList.has(id) && "active"} groups_list_item list-group-item-action cursor-pointer d-flex justify-content-between align-items-center`} onClick={() => this.onClick(id)}>
                <div className={"col-7 p-0"}>
                    <p className={"d-block list-group-countingsetups-list-name"}>{display_name}</p>
                </div>
                <div className={"col-5 p-0 d-flex align-items-center justify-content-center"}>
                    <span className={`badge w-100 badge-primary text-uppercase badge-pill list-group-countingsetups-list-status ${status === "active" ? "group_is_active" : ""} ${status === "inactive" ? "group_not_active" : ""} ${status === "requested" ? "group_is_requested" : ""}`}>
                        {(() => {
                            switch(status) {
                                case 'active':
                                    return <FormattedMessage id={"groups_list.status.active"}/>;
                                case 'inactive':
                                    return <FormattedMessage id={"groups_list.status.inactive"}/>;
                                case 'requested':
                                    return <FormattedMessage id={"groups_list.status.requested"}/>;
                                default:
                                    return "undefined";
                            }
                        })()}
                    </span>
                </div>
            </li>
        );
    }

    renderNewCountingSetup(countingSetup: NewCountingSetup, index: number): JSX.Element|null {
        const { name} = countingSetup;
        if (name == null) return null;
        return (
            <li key={Math.random()} className={`list-group-item groups_list_item list-group-item-action cursor-pointer d-flex justify-content-between align-items-center`}>
                <div className={"col-7 p-0"}>
                    <p className={"d-block list-group-countingsetups-list-name"}>{name}</p>
                </div>
                <div className={"col-5 p-0 d-flex align-items-center justify-content-center"}>
                    <i onClick={() => this.props.streetCloudContext.onInquiryCountingSetupDeleteClick(index)} className={"fas fa-times"}/>
                </div>
            </li>
        );
    }

    onClickLogout(): void {
        startLogout()
            .then(res => {
                window.location.replace(URI_AUTH_LOGIN);
            })
            .catch(err => {
                window.location.replace(URI_AUTH_LOGIN);
            });
    }

    render() {
        return (
            <div className={"SensorRightSidebar"}>
                <div className={"SensorRightSidebarChild"}>
                    <div className={"Logo justify-content-center d-flex align-items-center"}>
                        <img src={Logo} className={"SensorRightSidebarLogo img-fluid"} alt={"Logo"}/>
                        <button onClick={this.onClickLogout} type={"button"} className={"LogoutButton"}><i className={"fas fa-power-off"}/></button>
                    </div>
                    <div className={"Locations"}>
                        <hr/>
                        <div className={`SensorRightSidebarGroupsList`}>
                            <ul className={"list-group ml-1 mr-1 mb-3"}>
                                {this.props.streetCloudContext.countingSetups != null && this.props.streetCloudContext.countingSetups.filter(value => value.status != null && value.status !== "unknown" && value.location != null).map(this.renderCountingSetup)}
                            </ul>
                        </div>
                    </div>
                    <div className={`ClickMessage d-flex align-items-center justify-content-center ${this.props.streetCloudContext.show_new_inquiry && "show"}`}>
                        <p className={"text-center"}><FormattedMessage id={"select_location.modal.text"} defaultMessage={"Selecteer een plek op de map om een telopstelling toe te voegen"}/></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStreetCloudContext(Sidebar);
