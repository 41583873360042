import React from "react";
import {withStreetCloudContext, WithStreetCloudContextProps} from "../Context/StreetCloudContext";
import {Download, ModalTab} from "../../Models/Tabs";
import WeekPicker from "../WeekPicker/WeekPicker";
import dayjs from "dayjs";
import {ConfirmDialog, customConfirm} from "../Dialogs/Confirm/PromiseConfirm";
import {startDeleteSchedule} from "../../Actions/Schedule";
import {startGetCountingSetupDownloadLink} from "../../Actions/Download";
import {FormattedMessage} from "react-intl";
import {intlStore} from "../../index";

interface IProps extends WithStreetCloudContextProps {
    show: boolean;
    value: ModalTab;
}

interface IState {
    schedule_active_id: string | null;
    subTabIndex: number;
}

class FocusBottomTab extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            schedule_active_id: null,
            subTabIndex: 0
        }

        // Bindings
        this.onScheduleClick = this.onScheduleClick.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.switchSettingsTab = this.switchSettingsTab.bind(this);
        this.showDeleteCOModal = this.showDeleteCOModal.bind(this);
        this.renderDownload = this.renderDownload.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.downloadURI = this.downloadURI.bind(this);
    }

    onScheduleClick(id: string) {
        const { schedule_active_id } = this.state;

        this.setState({
            schedule_active_id: schedule_active_id === id ? null : id
        });
    }

    async showDeleteModal(e: React.MouseEvent<HTMLDivElement>, schedule_id: string): Promise<void> {
        e.stopPropagation();

        const { value } = this.props;

        await customConfirm(ConfirmDialog, {title: "Delete Schedule", content: "Are you sure you want to delete this schedule"})
            .then(res => {
                if (res) {
                    startDeleteSchedule(value.id, schedule_id)
                        .then(res => {
                            console.log(res);
                            this.props.streetCloudContext.startReloadOpenTabSchedules(this.props.value.id);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    async showDeleteCOModal(): Promise<void> {
        await customConfirm(ConfirmDialog, {title: intlStore.messages["bottom_tabs.focus.tabs.settings.delete.modal.title"] as string || "", content: intlStore.messages["bottom_tabs.focus.tabs.settings.delete.modal.content"] as string || ""})
            .then(res => {
                if (res) {
                    this.props.streetCloudContext.deleteCountingSetup(this.props.value.id);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    switchSettingsTab(index: number): void {
        if (this.state.subTabIndex === index) return;
        this.setState({
            subTabIndex: index
        });
    }

    onDownloadClick(id: string, filename: string): void {
        startGetCountingSetupDownloadLink(id, filename)
            .then(res => {
                if (res.data && res.data) {
                    this.downloadURI(res.data.filename, res.data.url);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    renderDownload(download: Download): JSX.Element {
        const { id } = this.props.value;
        return (
            <li key={Math.random()} className={`list-group-item schedule_list_item cursor-pointer list-group-item-action`} onClick={() => this.onDownloadClick(id, download.filename)}>
                <div className={"d-flex w-100 justify-content-between align-items-center"}>
                    <h5>{download.filename}</h5>
                    <small>{dayjs(download.last_modified).fromNow()}</small>
                </div>
            </li>
        );
    }

    downloadURI(name: string, url: string): void {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        link.click();
    }

    render() {
        return (
            <div className={`BottomTabsTab-Pane ${this.props.show ? "show" : ""}`}>
                <div className={"Tab"}>
                    <div className={"Tab-Content"}>
                        <div className={"row pl-2 pr-2"}>
                            <div className={"focus_bottom-left"}>
                                <WeekPicker title={"Selecteer een week range"} dataId={this.props.value.id}/>
                                <ul className={"list-group"}>
                                    {
                                        this.props.value.schedules != null
                                        &&
                                        this.props.value.schedules.sort((a, b) => new Date(a.start_date).getTime() > new Date(b.start_date).getTime() ? 1 : -1).map((value, index) => {
                                            return(
                                                <li className={`list-group-item schedule_list_item cursor-pointer list-group-item-action ${this.state.schedule_active_id === value.id && "active"}`} onClick={() => this.onScheduleClick(value.id)} key={Math.random()}>
                                                    <div className={"d-flex w-100 justify-content-between align-items-center"}>
                                                        <div className={"col-11 p-0"}>
                                                            <h6 className={"word-break-all schedule-list-heading"}>{dayjs(value.start_date).format("DD-MM-YYYY")} - {dayjs(value.end_date).format("DD-MM-YYYY")}</h6>
                                                        </div>
                                                        <div className={"col-1 p-0"}>
                                                            {/*<div className={`countingSetupDeleteButton ${moment(value.start_date).calendar() < moment().subtract(7, 'days').calendar() && index === this.state.schedule_active_id ? "active" : ""}`} onClick={(e) => this.showDeleteModal(e)}>*/}
                                                            {/*    <i className={"fas fa-times"}/>*/}
                                                            {/*</div>*/}
                                                            <div className={`countingSetupDeleteButton ${dayjs().isBefore(value.start_date, 'day') && this.state.schedule_active_id === value.id ? "active" : ""}`} onClick={(e) => this.showDeleteModal(e, value.id)}>
                                                                <i className={"fas fa-times"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small>
                                                        <FormattedMessage id={"bottom_tabs.focus.schedule_counting_setup.texts.start_week"} defaultMessage={"Startweek"}/>: {dayjs(value.start_date).week()}
                                                    </small>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                            <div className={"focus_bottom_right"}>
                                <div className={"pl-2 pr-2"}>
                                    <div className={"bottom_tab_settings_header"}>
                                        <h2 className={`bottom_tab_settings_headerItem ${this.state.subTabIndex === 0 && "active"}`} onClick={() => this.switchSettingsTab(0)}>
                                            <FormattedMessage id={"bottom_tabs.focus.tabs.info.title"} defaultMessage={"Info"}/>
                                        </h2>
                                        <h2 className={`bottom_tab_settings_headerItem ${this.state.subTabIndex === 1 && "active"}`} onClick={() => this.switchSettingsTab(1)}>
                                            <FormattedMessage id={"bottom_tabs.focus.tabs.downloads.title"} defaultMessage={"Downloads"}/>
                                        </h2>
                                        <h2 className={`bottom_tab_settings_headerItem ${this.state.subTabIndex === 2 && "active"}`} onClick={() => this.switchSettingsTab(2)}>
                                            <FormattedMessage id={"bottom_tabs.focus.tabs.settings.title"} defaultMessage={"Instellingen"}/>
                                        </h2>
                                    </div>
                                    <div className={"bottom_tab_settings_content"}>
                                        {(() => {
                                            switch (this.state.subTabIndex) {
                                                case 0:
                                                    return (
                                                        <div className={"d-flex justify-content-center mt-3"}>
                                                            <p className={"text-center"}>{this.props.value.countingSetup.description}</p>
                                                        </div>
                                                    );
                                                case 1:
                                                    return (
                                                        <div className={"d-flex justify-content-center mt-3"}>
                                                            <div className={"list-group w-50"}>
                                                                {
                                                                    this.props.value.downloads.sort((a, b) => new Date(b.last_modified).getTime() - new Date(a.last_modified).getTime()).map(this.renderDownload)
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                case 2:
                                                    return (
                                                        <div className={"d-flex justify-content-center mt-3"}>
                                                            <button className={"streetcloud_button streetcloud_delete_button red"} onClick={this.showDeleteCOModal}><FormattedMessage id={"bottom_tabs.focus.tabs.settings.buttons.delete"} defaultMessage={"Verwijderen"}/></button>
                                                        </div>
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStreetCloudContext(FocusBottomTab);
