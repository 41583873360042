import React, {useState} from "react";
import ReactDOM from "react-dom";
import "./Style/Style.sass";
import {NewCountingSetup} from "../../../Models/CountingSetup";
import {checkStringEmpty} from "../../../Validators/String";
import {checkNumberEmpty} from "../../../Validators/Number";
import {FormattedMessage} from "react-intl";
import {intlStore} from "../../../index";

export interface PromiseNewCountingSetupProps {
    location: {
        lat: number;
        lon: number;
    };
    name: string;
    space: string;
}

export interface PromiseNewCountingSetupResult {
    cancel: boolean;
    data: NewCountingSetup | null;
}

const dialogNode = document.getElementById('dialog-node');

export const newCountingSetupDialog = (
    DialogContent: (props: {
        giveAnswer: (answer: PromiseNewCountingSetupResult) => void,
        content: PromiseNewCountingSetupProps,
        cancel: string,
        oke: string
    }) => React.ReactElement,
    props: PromiseNewCountingSetupProps
): Promise<PromiseNewCountingSetupResult> =>
    new Promise(res => {
        if (dialogNode == null) return;
        const giveAnswer = (answer: PromiseNewCountingSetupResult) => {
            ReactDOM.unmountComponentAtNode(dialogNode)
            res(answer)
        }

        let cancel, oke: string;

        cancel = "Cancel";
        oke = "Oke";

        ReactDOM.render(<DialogContent cancel={cancel} oke={oke} giveAnswer={giveAnswer} content={props} />, dialogNode)
    })

interface IState {
    form: {
        location: {
            lat: number | null;
            lon: number | null;
        };
        name: string;
        description: string;
        direction_a: string;
        direction_b: string;
        space: string;
        two_lanes: boolean;
    };
    errors: {
        location: {
            lat: object;
            lon: object;
        };
        name: object;
        description: object;
    }
}

export const NewCountingSetupDialog = ({ giveAnswer, content, cancel, oke }) => {
    const [newCountingSetup, setNewCountingSetup] = useState<IState>({
        form: {
            location: content.location,
            name: content.name,
            description: "",
            direction_a: "",
            direction_b: "",
            space: content.space,
            two_lanes: false
        },
        errors: {
            location: {
                lat: {},
                lon: {}
            },
            name: {},
            description: {}
        }
    });
    const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        setNewCountingSetup({
            ...newCountingSetup,
            form: {
                ...newCountingSetup.form,
                [e.target.name]: e.target.value
            },
            errors: {
                location: {
                    lat: {},
                    lon: {}
                },
                name: {},
                description: {}
            }
        });
    };
    const onLaneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewCountingSetup({
            ...newCountingSetup,
            form: {
                ...newCountingSetup.form,
                two_lanes: e.target.checked
            },
            errors: {
                location: {
                    lat: {},
                    lon: {}
                },
                name: {},
                description: {}
            }
        });
    }
    const onLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { location } = newCountingSetup.form;
        location[e.target.name] = parseFloat(e.target.value);
        setNewCountingSetup({
            ...newCountingSetup,
            form: {
                ...newCountingSetup.form,
                location
            },
            errors: {
                location: {
                    lat: {},
                    lon: {}
                },
                name: {},
                description: {}
            }
        });
    };
    const onSubmit = () => {
        const { form, errors } = newCountingSetup;

        let foundValidationError = false;

        errors.name = {};
        errors.description = {};
        errors.location.lat = {};
        errors.location.lon = {};

        if (checkStringEmpty(form.name)) {
            errors.name["validators.empty"] = "validators.empty";
            foundValidationError = true;
        }
        if (checkNumberEmpty(form.location.lon)) {
            errors.location.lon["validators.empty"] = "validators.empty";
            foundValidationError = true;
        }
        if (checkNumberEmpty(form.location.lat)) {
            errors.location.lat["validators.empty"] = "validators.empty";
            foundValidationError = true;
        }
        if (form.two_lanes) {
            let directionA;
            let directionB;
            if (checkStringEmpty(form.direction_a) && checkStringEmpty(form.direction_b)) {
                directionA = "Dir A";
                directionB = "Dir B";
            } else if (checkStringEmpty(form.direction_a) && !checkStringEmpty(form.direction_b)) {
                directionA = form.direction_b;
                directionB = "Dir B";
            } else if (!checkStringEmpty(form.direction_a) && checkStringEmpty(form.direction_b)) {
                directionA = form.direction_a;
                directionB = "Dir B";
            }
            form.direction_a = directionA;
            form.direction_b = directionB;
        } else {
            if (checkStringEmpty(form.direction_a)) {
                form.direction_a = "Dir A";
            }
        }

        if (!form.two_lanes) {
            form.direction_b = "none";
        }

        if (foundValidationError) {
            setNewCountingSetup({
                ...newCountingSetup,
                errors: errors
            })
            return;
        } else {
            giveAnswer({data: newCountingSetup.form, cancel: false});
        }
    }
    const onClose = () => {
        giveAnswer({data: null, cancel: true});
    }
    return(
        <div className={"NewCountingSetupDialogWrapper"}>
            <div className={"NewCountingSetupDialog"}>
                <div className={"p-2 d-flex align-items-center justify-content-center"}>
                    <div className={"NewCountingSetupDialogContent mb-2"}>
                        <h1>{intlStore.messages["new_counting_setup.modal.title"] || "Nieuwe telopstelling"}</h1>
                        <div className={"NewCountingSetupDialogClose"} onClick={onClose}><i className={"fas fa-times"}/></div>
                        <div>
                            <div className={"row"}>
                                <div className={"col pr-1"}>
                                    <div className={"form-group"}>
                                        <label className={"streetcloud_form_label required"} htmlFor={"lon"}>{intlStore.messages["new_counting_setup.modal.longitude"] || "Lengtegraad"}</label>
                                        <input type={"number"} id={"lon"} className={"streetcloud_form_input"} value={newCountingSetup.form.location.lon || ""} step={"any"} name={"lon"} onChange={onLocationChange}/>
                                        <div className={"d-block"}>
                                            {Object.values(newCountingSetup.errors.location.lon).map((value => {
                                                return <div key={Math.random()} className={"new-item-error"}>{intlStore.messages[value] || ""}</div>;
                                            }))}
                                        </div>
                                    </div>
                                </div>
                                <div className={"col pl-1"}>
                                    <div className={"form-group"}>
                                        <label className={"streetcloud_form_label required"} htmlFor={"lat"}>{intlStore.messages["new_counting_setup.modal.latitude"] || "Breedtegraad"}</label>
                                        <input type={"number"} id={"lat"} className={"streetcloud_form_input"} value={newCountingSetup.form.location.lat || ""} step={"any"} name={"lat"} onChange={onLocationChange}/>
                                        <div className={"d-block"}>
                                            {Object.values(newCountingSetup.errors.location.lat).map((value => {
                                                return <div key={Math.random()} className={"new-item-error"}>{intlStore.messages[value] || ""}</div>;
                                            }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"form-group"}>
                                <label className={"streetcloud_form_label required"} htmlFor={"name"}>{intlStore.messages["new_counting_setup.modal.name"] || "Naam"}</label>
                                <input type={"text"} id={"name"} className={"streetcloud_form_input"} value={newCountingSetup.form.name || ""} name={"name"} onChange={onChange}/>
                                <div className={"d-block"}>
                                    {Object.values(newCountingSetup.errors.name).map((value => {
                                        return <div key={Math.random()} className={"new-item-error"}>{intlStore.messages[value] || ""}</div>;
                                    }))}
                                </div>
                            </div>
                            <div className={"form-group"}>
                                <label className={"streetcloud_form_label"} htmlFor={"description"}>{intlStore.messages["new_counting_setup.modal.description"] || "Omschrijving"}</label>
                                <textarea id={"description"} className={"streetcloud_form_input"} value={newCountingSetup.form.description || ""} name={"description"} onChange={onChange}/>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <div className={"form-group"}>
                                        <label className={"streetcloud_form_label"}>{ intlStore.messages["new_counting_setup.modal.two_lanes"] || "Twee rijbanen"}</label>
                                        <div className={"d-block"}>
                                            <label className={"switch"}>
                                                <input type={"checkbox"} checked={newCountingSetup.form.two_lanes} onChange={onLaneChange}/>
                                                <span className={"slider round"}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col pr-1"}>
                                    <div className={"form-group"}>
                                        <label className={"streetcloud_form_label"} htmlFor={"direction_a"}>{intlStore.messages["new_counting_setup.modal.direction_a"] || "Richting A"}</label>
                                        <input type={"text"} id={"direction_a"} className={"streetcloud_form_input"} value={newCountingSetup.form.direction_a} name={"direction_a"} onChange={onChange}/>
                                    </div>
                                </div>
                                <div className={"col pl-1"}>
                                    {
                                        newCountingSetup.form.two_lanes
                                        &&
                                        <div className={"form-group"}>
                                            <label className={"streetcloud_form_label"} htmlFor={"direction_b"}>{intlStore.messages["new_counting_setup.modal.direction_b"] || "Richting B"}</label>
                                            <input type={"text"} id={"direction_b"} className={"streetcloud_form_input"} value={newCountingSetup.form.direction_b} name={"direction_b"} onChange={onChange}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"form-group"}>
                                <button type={"button"} className={"streetcloud_button"} onClick={onSubmit}>{intlStore.messages["new_counting_setup.modal.submit"] || "Indienen"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
