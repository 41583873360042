import React from "react";
import {ParkingBay} from "../../Models/ParkingBay";
import {startGetAllParkingBays} from "../../Actions/ParkingBay";
import {Me} from "../../Models/Me";
import {startGetMe} from "../../Actions/Me";
import {CountingSetup, NewCountingSetup} from "../../Models/CountingSetup";
import {startDeleteCountingSetup, startGetAllCountingSetups, startPostCountingSetup} from "../../Actions/CountingSetup";
import {ModalTab} from "../../Models/Tabs";
import {startGetAllCountingSetupSchedules} from "../../Actions/Schedule";
import {Inquiry} from "../../Models/Inquiry";
import {
    AddNewCountingSetupToInquiry,
    NewInquiryCountingSetup,
    startAddCountingSetupsToInquiry,
    startCreateNewInquiry,
    startGetAllInquiries
} from "../../Actions/Inquiries";
import {Space} from "../../Models/Space";
import {startGetAllSpaces} from "../../Actions/Spaces";
import {ConfirmDialog, customConfirm} from "../Dialogs/Confirm/PromiseConfirm";
import {checkStringEmpty} from "../../Validators/String";
import {startGetAllCountingSetupDownloads} from "../../Actions/Download";

interface IProps {

}

interface IState {
    refreshTimestamp: Date | null;
    top: {
        height: string;
    };
    parkingBays: ParkingBay[] | null;
    spaces: Space[] | null;
    countingSetups: CountingSetup[] | null;
    totalData: Array<Array<number>> | null;
    me: Me | null;
    activeCountingSetupList: Set<string>;
    modalTabs: {
        activeModalTabID: string | null;
        modalTabs: ModalTab[];
    };
    inquiries: Inquiry[] | null;
    is_selecting_location: boolean;
    selecting_location: {
        lat: number | null,
        lon: number | null,
        name: string | null
    };
    add_inquiry_form: {
        name: string;
        description: string;
        counting_setups: NewCountingSetup[];
        errors: {
            name: object;
            description: object;
        };
        showed_choose_dialog: boolean;
        inquiry_id: string | null;
    };
    show_new_inquiry: boolean;
}

export interface StreetCloudContextInterface {
    refreshTimestamp: Date | null;
    top: {
        height: string;
    };
    changeBottomHeight: (height: string) => void;
    setActiveCountingSetup: (activeCountingSetupList: Set<string>) => void;
    parkingBays: ParkingBay[] | null;
    countingSetups: CountingSetup[] | null;
    totalData: Array<Array<number>> | null;
    me: Me | null;
    activeCountingSetupList: Set<string>;
    modalTabs: {
        activeModalTabID: string | null;
        modalTabs: ModalTab[];
    }
    sidebarOnClick: (id: string) => void;
    switchTab: (id: string) => void;
    closeTab: (id: string) => void;
    inquiries: Inquiry[] | null;
    onSubmitNewCountingSetup: (input: NewCountingSetup) => void;
    changeShowNewInquiry: (show: boolean) => void;
    show_new_inquiry: boolean;
    add_inquiry_form: {
        name: string;
        description: string;
        counting_setups: NewCountingSetup[];
        errors: {
            name: object;
            description: object;
        };
        showed_choose_dialog: boolean;
        inquiry_id: string | null;
    };
    onInquiryCountingSetupDeleteClick: (index: number) => void;
    onInquiryFormFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmitInquiry: () => void;
    spaces: Space[] | null;
    onCancelInquiry: () => void;
    startReloadOpenTabSchedules: (id: string) => void;
    deleteCountingSetup: (id: string) => void;
    onEmptySpotClick: () => void;
}

export type WithStreetCloudContextProps = {
    streetCloudContext: StreetCloudContextInterface;
};

const ctxt = React.createContext<StreetCloudContextInterface | null>(null);

ctxt.displayName = "StreetCloudContext";

export const StreetCloudContextProvider = ctxt.Provider;
export const StreetCloudContextConsumer = ctxt.Consumer;

export default class StreetCloudContextWrapper extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            refreshTimestamp: null,
            top: {
                height: "100%"
            },
            parkingBays: null,
            me: null,
            countingSetups: null,
            activeCountingSetupList: new Set<string>(),
            totalData: null,
            modalTabs: {
                activeModalTabID: null,
                modalTabs: []
            },
            inquiries: null,
            is_selecting_location: false,
            selecting_location: {
                lat: null,
                lon: null,
                name: null
            },
            add_inquiry_form: {
                name: "",
                description: "",
                counting_setups: [],
                errors: {
                    name: Object(),
                    description: Object(),
                },
                showed_choose_dialog: false,
                inquiry_id: null
            },
            show_new_inquiry: false,
            spaces: null
        }

        this.changeBottomHeight = this.changeBottomHeight.bind(this);
        this.getAllParkingBays = this.getAllParkingBays.bind(this);
        this.getAllCountingSetups = this.getAllCountingSetups.bind(this);
        this.setActiveCountingSetup = this.setActiveCountingSetup.bind(this);
        this.startAddNewTab = this.startAddNewTab.bind(this);
        this.startOpenTab = this.startOpenTab.bind(this);
        this.startCloseTab = this.startCloseTab.bind(this);
        this.sidebarOnClick = this.sidebarOnClick.bind(this);
        this.switchTab = this.switchTab.bind(this);
        this.closeTab = this.closeTab.bind(this);
        this.getAllInquiries = this.getAllInquiries.bind(this);
        this.onSubmitNewCountingSetup = this.onSubmitNewCountingSetup.bind(this);
        this.changeShowNewInquiry = this.changeShowNewInquiry.bind(this);
        this.onInquiryCountingSetupDeleteClick = this.onInquiryCountingSetupDeleteClick.bind(this);
        this.onInquiryFormFieldChange = this.onInquiryFormFieldChange.bind(this);
        this.getAllSpaces = this.getAllSpaces.bind(this);
        this.onSubmitInquiry = this.onSubmitInquiry.bind(this);
        this.onCancelInquiry = this.onCancelInquiry.bind(this);
        this.startReloadOpenTabSchedules = this.startReloadOpenTabSchedules.bind(this);
        this.deleteCountingSetup = this.deleteCountingSetup.bind(this);
        this.onEmptySpotClick = this.onEmptySpotClick.bind(this);
    }

    componentDidMount() {
        this.getAllCountingSetups();
        this.getMe();
        this.getAllInquiries();
        this.getAllSpaces();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.state.modalTabs.activeModalTabID === null && this.state.modalTabs.modalTabs.length === 0 && this.state.top.height !== "100%") {
            this.setState({
                top: {
                    ...this.state.top,
                    height: "100%"
                }
            })
        } else if (this.state.modalTabs.activeModalTabID === null && this.state.modalTabs.modalTabs.length > 0 && this.state.top.height !== "90%") {
            this.setState({
                top: {
                    ...this.state.top,
                    height: "90%"
                }
            })
        } else if (this.state.modalTabs.activeModalTabID !== null && this.state.modalTabs.modalTabs.length > 0 && this.state.top.height !== "50%") {
            this.setState({
                top: {
                    ...this.state.top,
                    height: "50%"
                }
            })
        }
        if (prevState.show_new_inquiry !== this.state.show_new_inquiry) {
            const { show_new_inquiry, top, modalTabs } = this.state;
            if (show_new_inquiry) {
                if (top.height !== "100%") {
                    this.setState({
                        top: {
                            ...this.state.top,
                            height: "100%"
                        }
                    });
                }
            } else {
                if (modalTabs.modalTabs.length > 0 && top.height !== "90%" && modalTabs.activeModalTabID === null) {
                    this.setState({
                        top: {
                            ...this.state.top,
                            height: "90%"
                        }
                    });
                } else if (modalTabs.modalTabs.length > 0 && top.height !== "50%" && modalTabs.activeModalTabID !== null) {
                    this.setState({
                        top: {
                            ...this.state.top,
                            height: "50%"
                        }
                    });
                } else if (modalTabs.modalTabs.length === 0 && top.height !== "100%") {
                    this.setState({
                        top: {
                            ...this.state.top,
                            height: "100%"
                        }
                    })
                }
            }
        }
    }

    getAllInquiries(): void {
        startGetAllInquiries()
            .then(res => {
                this.setState({
                    inquiries: res.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getAllSpaces(): void {
        startGetAllSpaces()
            .then(res => {
                this.setState({
                    spaces: res.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    async changeShowNewInquiry(show: boolean): Promise<void> {
        this.setActiveCountingSetup(new Set<string>());
        this.setState({
            show_new_inquiry: show
        });
    }

    startAddNewTab(id: string): void {
        const { countingSetups, modalTabs } = this.state;
        if (countingSetups == null) return;

        const findCountingSetup = countingSetups.find(value => value.id === id);
        if (findCountingSetup == null) return;

        modalTabs.modalTabs.push({
            id: findCountingSetup.id,
            title: findCountingSetup.display_name,
            schedules: [],
            downloads: [],
            loadedDownloads: false,
            loadedSchedules: false,
            countingSetup: findCountingSetup
        });

        modalTabs.activeModalTabID = id;

        this.setState({
            modalTabs: modalTabs
        });

        startGetAllCountingSetupSchedules(id)
            .then(res => {
                if (res.data != null) {
                    modalTabs.modalTabs[modalTabs.modalTabs.length - 1].loadedSchedules = true;
                    modalTabs.modalTabs[modalTabs.modalTabs.length - 1].schedules = res.data;
                    this.setState({
                        modalTabs: modalTabs
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });

        startGetAllCountingSetupDownloads(id)
            .then(res => {
                if (res.data != null) {
                    modalTabs.modalTabs[modalTabs.modalTabs.length - 1].loadedDownloads = true;
                    modalTabs.modalTabs[modalTabs.modalTabs.length - 1].downloads = res.data;
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    deleteCountingSetup(id: string): void {
        startDeleteCountingSetup(id)
            .then(res => {
                if (res.data && res.data) {
                    this.closeTab(id);
                    this.getAllCountingSetups();
                }
            })
            .catch(err => {
                this.closeTab(id);
                this.getAllCountingSetups();
            });
    }

    switchTab(id: string): void {
        const { modalTabs } = this.state;
        if (modalTabs.activeModalTabID !== id) {
            this.setActiveCountingSetup(new Set<string>().add(id));
            this.startOpenTab(id);
        } else if (modalTabs.activeModalTabID === id) {
            this.setActiveCountingSetup(new Set<string>());
            this.setState({
                modalTabs: {
                    ...this.state.modalTabs,
                    activeModalTabID: null
                }
            });
        }
    }

    closeTab(id: string): void {
        const { modalTabs } = this.state;
        if (modalTabs.activeModalTabID === id) {
            this.setActiveCountingSetup(new Set<string>());
            this.startCloseTab(id);
        } else {
            this.startCloseTab(id);
        }
    }

    startOpenTab(id: string): void {
        const { modalTabs } = this.state;

        modalTabs.activeModalTabID = id;

        this.setState({
            modalTabs: modalTabs
        });
    }

    startCloseTab(id: string): void {
        const { modalTabs } = this.state;

        modalTabs.activeModalTabID = (modalTabs.activeModalTabID === id ? null : modalTabs.activeModalTabID);
        modalTabs.modalTabs = modalTabs.modalTabs.filter(modalTab => modalTab.id !== id);

        this.setState({
            modalTabs: modalTabs
        });
    }

    changeBottomHeight(height: string): void {
        this.setState({
            top: {
                ...this.state.top,
                height: height
            }
        });
    }

    setActiveCountingSetup(activeCountingSetupList: Set<string>): void {
        this.setState({
            activeCountingSetupList: activeCountingSetupList,
            top: {
                ...this.state.top,
                height: activeCountingSetupList.size === 0 ? "100%" : "50%"
            }
        });
    }

    sidebarOnClick(id: string): void {
        const { countingSetups, modalTabs, show_new_inquiry } = this.state;
        if (countingSetups == null) return;

        if (show_new_inquiry) {
            this.setState({
                show_new_inquiry: false
            });
        }

        if (modalTabs.activeModalTabID !== id) {
            const findModal = modalTabs.modalTabs.find(value => value.id === id);

            if (findModal != null) {
                this.setActiveCountingSetup(new Set<string>().add(id));
                this.startOpenTab(id);
            } else {
                this.setActiveCountingSetup(new Set<string>().add(id));
                this.startAddNewTab(id);
            }
        }
    }

    onEmptySpotClick(): void {
        this.setActiveCountingSetup(new Set<string>());
        this.setState({
            modalTabs: {
                ...this.state.modalTabs,
                activeModalTabID: null
            }
        });
    }

    getAllParkingBays(): void {
        startGetAllParkingBays()
            .then(res => {
               this.setState({
                   parkingBays: res.data
               });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getMe(): void {
        startGetMe()
            .then(res => {
                this.setState({
                    me: res.data
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getAllCountingSetups(): void {
        startGetAllCountingSetups()
            .then(res => {
                const totalData: Array<Array<number>> = [];
                if (res.data != null) {
                    res.data.forEach(value => {
                        if (value.location != null) {
                            value.location.coordinates.forEach(value1 => {
                                totalData.push(value1);
                            })
                        }
                    })
                }
                this.setState({
                    countingSetups: res.data,
                    totalData: totalData
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    onSubmitNewCountingSetup(input: NewCountingSetup): void {
        const { show_new_inquiry } = this.state;
        if (show_new_inquiry) {
            startPostCountingSetup({
                display_name: input.name,
                description: input.description,
                space: input.space,
                name_positive: input.direction_a,
                name_negative: input.direction_b,
                location: input.location
            })
                .then(res => {
                    console.log(res);
                    this.getAllCountingSetups();
                    this.changeShowNewInquiry(false);
                })
                .catch(err => {
                    console.log(err);
                    this.getAllCountingSetups();
                    this.changeShowNewInquiry(false);
                });
        }
    }

    emptyInquiryForm(): void {
        this.setState({
            add_inquiry_form: {
                name: "",
                description: "",
                counting_setups: [],
                errors: {
                    name: Object(),
                    description: Object(),
                },
                showed_choose_dialog: false,
                inquiry_id: null
            }
        })
    }

    onSubmitInquiry(): void {
        // Finally empty the add_inquiry_form state
        const { add_inquiry_form, spaces } = this.state;
        if (spaces == null) return;

        if (add_inquiry_form.inquiry_id !== null && add_inquiry_form.showed_choose_dialog) {
            const counting_setups: AddNewCountingSetupToInquiry[] = [];

            add_inquiry_form.counting_setups.forEach(value => {
                let name_positive, name_negative;
                if (checkStringEmpty(value.direction_a) && checkStringEmpty(value.direction_b)) {
                    name_positive = Math.random().toString(36).substring(7);
                    name_negative = Math.random().toString(36).substring(7);
                } else if (checkStringEmpty(value.direction_a) && !checkStringEmpty(value.direction_b)) {
                    name_positive = value.direction_b;
                } else if (!checkStringEmpty(value.direction_a) && !checkStringEmpty(value.direction_b)) {
                    name_positive = value.direction_a;
                    name_negative = value.direction_b;
                } else if (!checkStringEmpty(value.direction_a) && checkStringEmpty(value.direction_b)) {
                    name_positive = value.direction_a;
                }
                counting_setups.push({
                    name: value.name,
                    description: value.description,
                    name_positive: name_positive,
                    name_negative: name_negative,
                    location: {
                        lat: value.location.lat,
                        lon: value.location.lon
                    },
                    space: "24de407a"
                });
            });

            startAddCountingSetupsToInquiry({
                inquiry_id: add_inquiry_form.inquiry_id,
                counting_setups: counting_setups
            })
                .then(res => {
                    console.log(res);
                    this.getAllCountingSetups();
                    this.getAllInquiries();
                    this.getAllSpaces();
                    this.emptyInquiryForm();
                    this.setState({
                        show_new_inquiry: false
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            let foundValidationError = false;

            add_inquiry_form.errors.name = {};
            add_inquiry_form.errors.description = {};

            if (checkStringEmpty(add_inquiry_form.name)) {
                add_inquiry_form.errors.name["validators.empty"] = "validators.empty";
                foundValidationError = true;
            }

            if (checkStringEmpty(add_inquiry_form.description)) {
                add_inquiry_form.errors.description["validators.empty"] = "validators.empty";
                foundValidationError = true;
            }

            if (foundValidationError) {
                this.setState({
                    add_inquiry_form: {
                        ...this.state.add_inquiry_form,
                        errors: add_inquiry_form.errors
                    }
                });
                return;
            }

            const counting_setups: NewInquiryCountingSetup[] = [];

            add_inquiry_form.counting_setups.forEach(value => {
                let name_positive, name_negative;
                if (checkStringEmpty(value.direction_a) && checkStringEmpty(value.direction_b)) {
                    name_positive = Math.random().toString(36).substring(7);
                    name_negative = Math.random().toString(36).substring(7);
                } else if (checkStringEmpty(value.direction_a) && !checkStringEmpty(value.direction_b)) {
                    name_positive = value.direction_b;
                } else if (!checkStringEmpty(value.direction_a) && !checkStringEmpty(value.direction_b)) {
                    name_positive = value.direction_a;
                    name_negative = value.direction_b;
                } else if (!checkStringEmpty(value.direction_a) && checkStringEmpty(value.direction_b)) {
                    name_positive = value.direction_a;
                }
                counting_setups.push({
                    name: value.name,
                    description: value.description,
                    name_positive: name_positive,
                    name_negative: name_negative,
                    location: {
                        lat: value.location.lat,
                        lon: value.location.lon
                    },
                    space: "24de407a"
                })
            });

            if (add_inquiry_form.name == null || add_inquiry_form.description == null) return;

            startCreateNewInquiry({
                name: add_inquiry_form.name,
                description: add_inquiry_form.description,
                counting_setups: counting_setups
            })
                .then(res => {
                    this.getAllCountingSetups();
                    this.getAllInquiries();
                    this.getAllSpaces();
                    this.emptyInquiryForm();
                    this.setState({
                        show_new_inquiry: false
                    });
                    console.log(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    async onCancelInquiry(): Promise<void> {
        await customConfirm(ConfirmDialog, {title: "Delete Inquiry", content: "Are you sure you want to delete this inquiry?"})
            .then(res => {
                if (res) {
                    this.emptyInquiryForm();
                    this.setState({
                        show_new_inquiry: false
                    });
                }
            })
    }

    onInquiryFormFieldChange(e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): void {
        const { add_inquiry_form } = this.state;
        add_inquiry_form[e.target.name] = e.target.value;
        add_inquiry_form.errors.name = {};
        add_inquiry_form.errors.description = {};

        this.setState({
            add_inquiry_form: add_inquiry_form
        });
    }

    onInquiryCountingSetupDeleteClick(index: number): void {
        const { counting_setups } = this.state.add_inquiry_form;
        counting_setups.splice(index, 1);
        this.setState({
            add_inquiry_form: {
                ...this.state.add_inquiry_form,
                counting_setups: counting_setups
            }
        })
    }

    startReloadOpenTabSchedules(id: string): void {
        const { modalTabs } = this.state;

        startGetAllCountingSetupSchedules(id)
            .then(res => {
                const data = res.data;
                if (data !== null) {
                    modalTabs.modalTabs.forEach((value, index) => {
                        if (value.id === id) {
                            modalTabs.modalTabs[index].schedules = data;
                            modalTabs.modalTabs[index].loadedSchedules = true;
                            this.setState({
                                modalTabs: modalTabs
                            });
                            return;
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err);
            });

        startGetAllCountingSetupDownloads(id)
            .then(res => {
                modalTabs.modalTabs.forEach((value, index) => {
                    if (value.id === id && res.data !== null) {
                        modalTabs.modalTabs[index].loadedDownloads = true;
                        modalTabs.modalTabs[index].downloads = res.data;
                        this.setState({
                            modalTabs: modalTabs
                        });
                        return;
                    }
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        return (
            <StreetCloudContextProvider value={{
                ...this.state,
                changeBottomHeight: this.changeBottomHeight,
                setActiveCountingSetup: this.setActiveCountingSetup,
                sidebarOnClick: this.sidebarOnClick,
                switchTab: this.switchTab,
                closeTab: this.closeTab,
                onSubmitNewCountingSetup: this.onSubmitNewCountingSetup,
                changeShowNewInquiry: this.changeShowNewInquiry,
                onInquiryCountingSetupDeleteClick: this.onInquiryCountingSetupDeleteClick,
                onInquiryFormFieldChange: this.onInquiryFormFieldChange,
                onSubmitInquiry: this.onSubmitInquiry,
                onCancelInquiry: this.onCancelInquiry,
                startReloadOpenTabSchedules: this.startReloadOpenTabSchedules,
                deleteCountingSetup: this.deleteCountingSetup,
                onEmptySpotClick: this.onEmptySpotClick
            }}>
                {this.props.children}
            </StreetCloudContextProvider>
        );
    }
}

export function withStreetCloudContext<P extends WithStreetCloudContextProps>(Component: React.ComponentType<P>) {
    return function AdminUsersUsersComponent(props: Pick<P, Exclude<keyof P, keyof WithStreetCloudContextProps>>) {
        return(
            <StreetCloudContextConsumer>
                {(streetcloud) => <Component {...props as P} streetCloudContext={streetcloud}/>}
            </StreetCloudContextConsumer>
        );
    }
}
